@import "../../../../scss/breakpoints";
:host,itineraire-section{
  section{
    padding-top:0 !important;
  }
  .map-container{
    position: relative;
    height:80svh;
    synapi-map{
      display: block;
      position: absolute;
      right:0;
      width: 50vw;
    }
  }
  .itineraire-container{
    padding-right:0;
    overflow: hidden;
  }
  .itineraire-content{
    margin-top:1px;
    display: flex;
    flex-direction: column;
  }
  .itineraire-informations{
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-spacing-m);
    background: var(--surface-complementary);
    color: var(--text-complementary-dark);
    .itineraire-information{
      display: flex;
      gap: var(--spacing-spacing-s);
      align-items: center;
      font-weight: 700;
      i{
        font-size: 1.6rem;
      }
    }
  }
  .itineraire-places{
    padding: var(--spacing-spacing-m);
    display:flex;
    flex-direction: column;
    gap: var(--spacing-spacing-m);
    flex:1;
    height:0;
    overflow: auto;
    .itineraire-place{
      background: var(--surface-brand-light);
      .place-top{
        padding: var(--spacing-spacing-m);
        display: flex;
        gap: var(--spacing-spacing-l);
        position: relative;
        height:205px;
        &:hover{
          color : var(--surface-brand-light);
        }
        &:after{
          position: absolute;
          height: 100%;
          width:100%;
          background: rgba(0,0,0,0.6);
          top:0;
          z-index: 1;
          left:0;
          content:'';
        }
        .place-background{
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
        .place-top-left{
          position: relative;
          z-index: 2;
          flex:1;
          .place-title-container{
            display: flex;
            align-items: center;
            gap: var(--spacing-spacing-m);
            .place-title{
              margin-bottom: var(--spacing-spacing-s);
            }
          }
        }
        .place-top-right{
          position: relative;
          z-index: 2;
          .place-image-container{
            width:220px;
            height:220px;
            position: relative;
          }
        }
        .place-description{
          line-height: 1.1em;
          margin-top: var(--spacing-spacing-s);
          font-size:0.9rem;
        }
      }
      .place-bottom{
        padding: var(--spacing-spacing-m);
        .place-tabs{
          display: flex;
          gap: var(--spacing-spacing-m);
          >li{
            padding: var(--spacing-spacing-xs) var(--spacing-spacing-m);
            background: var(--white);
            opacity: 0.8;
            font-weight: 600;
            white-space: nowrap;
            &:not(.active){
              cursor:pointer;
              &:hover{
                opacity: 0.9;
              }
            }
            &.active{
              opacity: 1;
            }
          }
        }
        .place-tab{
          padding: var(--spacing-spacing-m);
          background: var(--white);
          line-height: 1.2em;
        }
      }
    }
  }
}
@media (max-width: $tablet-breakpoint){
  :host,itineraire-section{
    section{
      padding-top:0 !important;
    }
    .itineraire-container{
      display: flex;
      flex-direction: column;
      padding:0;
      .map-container{
        order:2;
        height: 50svh;
        synapi-map{
          width: 100%;
          position: static;
        }
      }
      .itineraire-content{
        height: auto;
      }
      .itineraire-places{
        .itineraire-place{
          .place-bottom{
            padding-top:0;
          }
          .place-top{
            flex-direction: column;
            height: auto;
            .place-background{
              height: calc(100% - 180px);
            }
            .place-top-right{
              .place-image-container{
                width:100%;
              }
            }
            &:after{
              height: calc(100% - 180px);
            }
          }
        }
      }
    }
  }
}

